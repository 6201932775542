<template>
    <div id="app">
        <a-modal
                :visible="show"
                :title="title"
                :width="600"
                centered
                @cancel="_close">
            <a-form-model ref="menuForm" :model="formData" :rules="formRule">
                <a-row :gutter="8">
                    <a-col span="12">
                        <a-form-model-item label="菜单名称" label-position="top" prop="name">
                            <a-input v-model="formData.name" placeholder="请输入菜单名称" />
                        </a-form-model-item>
                    </a-col>
                    <a-col span="12">
                        <a-form-model-item label="上级节点" label-position="top" prop="parentId">
                            <a-cascader :options="mList" v-model="parentIds" changeOnSelect @change="setParentId"></a-cascader>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="8">
                    <a-col span="12">
                        <a-form-model-item label="文件路径" label-position="top" prop="path">
                            <a-input v-model="formData.path" placeholder="setting/admin/index" />
                        </a-form-model-item>
                    </a-col>
                    <a-col span="8">
                        <a-form-model-item label="权限规则" label-position="top" prop="authorize">
                            <a-input v-model="formData.authorize" placeholder="setting:admin:index" />
                        </a-form-model-item>
                    </a-col>
                    <a-col span="4">
                        <a-form-model-item label="排序" label-position="top" prop="sort">
                            <a-input-number :style="{width:'100%'}" v-model="formData.sort"></a-input-number>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="8">
                    <a-col span="10">
                        <a-form-model-item label="图标" label-position="top" prop="icon">
                            <a-input v-model="formData.icon" placeholder="icon-plus" />
                        </a-form-model-item>
                    </a-col>
                    <a-col span="7">
                        <a-form-model-item label="菜单类型" label-position="top" prop="isButton">
                            <a-radio-group  button-style="solid" v-model="formData.isButton">
                                <a-radio-button :value="0" :true-value="0" >菜单</a-radio-button>
                                <a-radio-button :value="1" :false-value="1" >按钮</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                    <a-col span="7">
                        <a-form-model-item label="状态" label-position="top" prop="isShow">
                            <a-radio-group  button-style="solid" v-model="formData.isShow">
                                <a-radio-button :value="1" :true-value="1" >显示</a-radio-button>
                                <a-radio-button :value="0" :false-value="0" >不显示</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <div class="demo-drawer-footer">
                <a-button style="margin-right: 8px" @click="_close">取消</a-button>
                <a-button type="primary" @click="doSubmit">确认提交</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import request from "@/config/request";
    import utils from "@/common/utils";
    export default {
        name: "menuAdd",
        data(){
            return{
                show:false,
                title:'添加菜单',
                styles: {
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: '53px',
                    position: 'static'
                },
                mList:[],
                parentIds:[],
                formData: {
                    name:'',
                    parentId:'',
                    icon:'',
                    path:'',
                    authorize:'',
                    isMenu:1,
                    isButton:0,
                    isShow:1,
                    sort:0,
                    grouping:'',
                },
                formRule:{
                    name:[
                        {required:true,message:'请输入名称'}
                    ],
                    parentId:[
                        {required:true,type:'number',min:0,message:'请选择挂载节点'}
                    ]
                }
            }
        },
        methods:{
            _show(form){
                this.show = true;
            },
            _close(){
                this.show = false;
                this.$emit('close')
            },
            getParentIds(pid){
                function findMenuIds(menuList,pid) {
                    let ids = [];
                    let obj = findMenuId(menuList,pid);
                    ids.push(obj.menuId);
                    while (obj.parentId>0){
                        obj = findMenuId(menuList,obj.parentId)
                        ids.push(obj.menuId);
                    }
                    return ids;
                }
                function findMenuId(menuList,pid){
                    let res = null;
                    for (let i=0;i<menuList.length;i++){
                        let x = menuList[i];
                        if(x.menuId==pid){
                            res = x;
                            break;
                        }else{
                            if(x.children && x.children.length>0){
                                res = findMenuId(x.children,pid);
                                if(res!=null){
                                    break;
                                }
                            }
                        }
                    }
                    return res;
                }
                console.log(this.mList)
                this.parentIds = findMenuIds(this.mList,pid).reverse();
            },
            setParentId(value){
                let len = value.length;
                this.formData.parentId = value[len-1];
            },
            doSubmit(){
                this.$refs.menuForm.validate((valid) => {
                    if (valid) {
                        utils.loading();
                        this.formData.grouping = this.group;
                        request.post('/platform/menu/save',this.formData).then(ret=>{
                            // console.log(ret)
                            if(ret.code==200){
                                utils.success('操作成功！').then(()=>{
                                    this.$refs.menuForm.resetFields();
                                    this.show = false;
                                    this.$emit('success');
                                })
                            }
                        }).catch(err=>{
                            console.log(err)
                        })
                    }
                })
            }
        },
        props:{
            form:{
                type:Object,
                default:()=>{ return;}
            },
            group:{
                type:String,
                default:()=>{ return "";}
            },
            pid:{
                type:Number,
                default:0,
            },
            menuList:{
                type:Array,
                default:()=>{ return [];}
            }
        },
        watch:{
            form(val){
                this.$nextTick(()=>{
                    this.$refs.menuForm.resetFields();
                    this.parentIds = [];
                    if(!val){
                        this.formData.menuId = '';
                        this.title = '添加菜单';
                        return;
                    }
                    this.title = '修改菜单';
                    this.formData = JSON.parse(JSON.stringify(val));
                    this.getParentIds(this.formData.parentId);
                })
            },
            pid(val){
                if (val){
                    this.formData.parentId = val;
                }else{
                    this.formData.parentId = 0;
                }
                this.getParentIds(val);
            },
            menuList(val){
                let first = [{label:'作为一级菜单',value:0,menuId:0,children:[]}];
                let merge = first.concat(val);
                return this.mList = merge;
            }
        },

    }
</script>

<style scoped>
    .demo-drawer-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 9;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #fff;
    }
</style>
