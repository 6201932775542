<template>
    <div id="ap">
        <a-layout>
            <div class="content-header">
                <a-row type="flex" justify="space-between">
                    <a-col span="12">
                        <common-page-btn @save="addMenu()"></common-page-btn>
                    </a-col>
                    <a-col span="12">
                        <a-row type="flex" align="middle" justify="end" :gutter="3">
                            <a-col span="6">
                                <a-select v-model="grouping" @change="getList" style="width: 100%">
                                    <a-select-option value="service-platform">总后台</a-select-option>
                                    <a-select-option value="service-appapi">客户端APP</a-select-option>
                                    <!--                            <a-select-option value="Station">站点后台</a-select-option>-->
                                </a-select>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </div>
        </a-layout>

        <a-layout>
            <a-layout-content>
                <a-table  :scroll="{x:'100%'}" :pagination="false" :style="{background:'#FFF'}" size="small" row-key="menuId" border :columns="keys" ref="list" :loading="loading" :data-source="data" @expand="expand">
                    <!--名称-->
                    <template slot-scope="row" slot="name">
                        <template v-if="row.icon">
                            <a-icon :type="row.icon"/>
                        </template>
                        {{row.name}}
                    </template>
                    <!--菜单-->
                    <template slot-scope="row" slot="is_menu">
                        <template v-if="row.isMenu==1">
                            <a-icon type="check-circle"  style="color:#090"/>
                        </template>
                        <template v-else>
                            <a-icon type="stop"/>
                        </template>
                    </template>
                    <!--按钮-->
                    <template slot-scope="row" slot="is_button">
                        <template v-if="row.isButton==1">
                            <a-icon type="check-circle"  style="color:#090"/>
                        </template>
                        <template v-else>
                            <a-icon type="stop"/>
                        </template>
                    </template>
                    <!--状态-->
                    <template slot-scope="row" slot="is_show">
                        <template v-if="row.isShow==1">
                            <a-icon type="check-circle" style="color: #090" />
                        </template>
                        <template v-else>
                            <a-icon type="stop"/>
                        </template>
                    </template>
                    <!--排序-->
                    <template slot-scope="row" slot="sort">
                        <a-input-number :min="0" size="small" :value="row.sort" @change="setSort"></a-input-number>
                    </template>
                    <!--操作-->
                    <template slot-scope="row" slot="action">
                        <a-button-group size="small">
                            <a-tooltip title="编辑"><a-button shape="circle" type="link" v-has="'menu:save'" icon="edit" @click="addMenu(row)"></a-button></a-tooltip>
                            <a-tooltip title="增加子菜单"><a-button shape="circle" type="link" v-has="'menu:save'" icon="plus-circle" @click="addChild(row)"></a-button></a-tooltip>
                            <a-tooltip title="删除"><a-button shape="circle" type="link" v-has="'menu:del'" icon="delete" @click="doDel(row)"></a-button></a-tooltip>
                        </a-button-group>
                    </template>
                </a-table>
            </a-layout-content>
        </a-layout>
        <a-back-top />
        <menu-add ref="form" :pid="pid" :form="row" :group="grouping" :menuList="data" @success="getList"></menu-add>
    </div>
</template>

<script>
    import menuAdd from "@/views/system/menu/menuAdd";
    export default {
        components:{menuAdd},
        data() {
            return {
                loading: false,
                data: [],
                row:null,
                grouping:"service-platform",
                pid:0,
                keys: [
                    {title: "菜单名称", scopedSlots: { customRender: 'name' }, align: 'left',tree: true},
                    // {title: 'ID', dataIndex: "menuId", width: 100, align: 'center'},
                    {title: "路径", dataIndex: "path", align: 'left'},
                    {title: "权限", dataIndex: "authorize", align: 'left'},
                    // {title: "排序", scopedSlots: { customRender: 'sort' }, align: 'center'},
                    {title: "菜单", scopedSlots: { customRender: 'is_menu' }, align: 'center', width: 80},
                    {title: "按钮", scopedSlots: { customRender: 'is_button' }, align: 'center', width: 80},
                    {title: "显示", scopedSlots: { customRender: 'is_show' }, align: 'center', width: 80},
                    {title: "操作", scopedSlots: { customRender: 'action' }, align: "center", width: 120}
                ]
            }
        },
        created() {
            this.getList();
        },
        methods: {
            // 添加修改
            addMenu(item){
                this.row = item ? item : null;
                this.$refs.form._show();
            },
            //添加子节点
            addChild(row){
                this.row = null;
                this.pid = row.menuId;
                this.$refs.form._show();
            },
            // 删除
            doDel(row){
                this.utils.confirm('删除后将不能恢复,您确定要删除吗？').then(()=>{
                    this.utils.loading();
                    this.http.delete("/platform/menu/del/"+row.menuId).then(ret=>{
                        // console.log(ret)
                        if(ret.code==200){
                            this.utils.success('删除成功！').then(()=>{
                                this.getList();
                            })
                        }else{
                            this.utils.error(ret.message);
                        }
                    }).catch(()=>{ return;})
                })
            },
            // 获取数据
            getList() {
                this.utils.loading();
                this.loading = true;
                this.http.get('/platform/menu/list', {group:this.grouping,parentId:0}).then(ret => {
                    this.loading = false;
                    this.data = ret.data;
                }).catch(err => {
                    this.utils.closeAll()
                })
            },
            //展开某行
            expand(state,item){
                if (state && item.children){
                    let list = [];
                    item.children.forEach(x=>{
                        if(x.children && x.children.length<1){
                            delete x.children;
                        }
                        list.push(x);
                    })
                    item.children = JSON.parse(JSON.stringify(list));
                }
            }
        }
    }
</script>

<style scoped>

</style>
